import { gql } from "@apollo/client";

const QueueCounts = gql`
  query QueueCounts {
    queueCounts {
      counts {
        queue
        count
      }
    }
  }`

export default QueueCounts;
