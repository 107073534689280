import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles'
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { ProductDescriptor } from '../commonStyledComponents';

const ProductDescription = ({ product, style }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...style }} display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <ProductDescriptor>
          Title
        </ProductDescriptor>
        <Box mb={5} component="h3">
          {product?.title}
        </Box>
        <Box mb={5}>
          <ProductDescriptor>
            Product ID
          </ProductDescriptor>
          <Typography>
            {product?.product_id}
          </Typography>
        </Box>
        <ProductDescriptor>
          Description
        </ProductDescriptor>
        <Box
          mb={5}
          p={1}
          sx={{
            maxHeight: '250px',
            overflow: 'scroll',
            border: `1px solid ${theme.palette.taupe[500]}`,
            borderRadius: '4px',
            wordBreak: 'break-word',
          }}
        >
          {product?.description}
        </Box>
      </Box>
      {product?.online_store_url && (
        <Box>
          <a href={product.online_store_url} rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faLink} size="sm" />
            &nbsp;View on store
          </a>
        </Box>
      )}
    </Box>
  );
};

ProductDescription.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
};

ProductDescription.defaultProps = {
  style: {},
};

export default ProductDescription;
