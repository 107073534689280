import React from 'react';
import { Box } from '@mui/material';

const boldLastBreadcrumb = (string, justifyContent) => {
  if (!string) return null;

  const breadcrumbs = string.split(' -> ');
  let lastWord = breadcrumbs.pop();

  lastWord = <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{lastWord}</span>

  return (
    <Box display="flex" flexWrap="wrap" justifyContent={justifyContent} gap={0.3}>
      {breadcrumbs.map((b) => {
        return (
          <Box key={b} display="flex" gap={0.3}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {b}
            </span>
            <span>
              &gt;
            </span>
          </Box>
        );
      })}
      {lastWord}
    </Box>
  );
};

export default boldLastBreadcrumb;
