import React, { useState } from "react";
import {
  Box, Button, TextField, TableContainer, TableRow,
  TableHead, Table, TableCell, TableBody, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, FormControlLabel, Radio, RadioGroup,
  Snackbar,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom";
import IntroHeader from "../components/IntroHeader";
import { IntroPageContent } from '../commonStyledComponents';
import { AuditLogs } from '../gql/queries';
import { SetProductQueue } from "../gql/mutations";

const Audit = ({ enableView }) => {
  const [search, setSearch] = useState({
    productId: '',
    vaId: '',
  });
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedQueue, setSelectedQueue] = useState('');
  const [busy, setBusy] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [mutate] = useMutation(SetProductQueue);
  const [getAudit, { data, loading }] = useLazyQuery(
    AuditLogs,
    {
      variables: {
        actor_id: search.vaId,
        product_id: search.productId ? /^gid:\/\/shopify\//.test(search.productId) ? search.productId : `gid://shopify/Product/${search.productId}` : null,
      },
      fetchPolicy: 'network-only',
    },
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  if (!enableView) return <Navigate to="/" />;

  const handleOpenModal = (productId) => {
    setSelectedProduct(productId);
    setOpenModal(true);
  }

  const handleClose = () => {
    setOpenModal(false);
  }

  const sendToQueue = async () => {
    setBusy(true);
    try {
      await mutate({
        variables: {
          set_product_queue: {
            product_id: selectedProduct,
            queue: selectedQueue,
          },
        },
      });
      setOpenSnackbar(true);
      setOpenModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setBusy(false);
    }
  }

  const handleGetAudit = (e) => {
    e.preventDefault();
    console.log('getting audit details');
    getAudit();
  }

  return (
    <Box>
      <IntroHeader />
      <IntroPageContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box textAlign="center">
          <Box component="h2">
            Enter the VA or Product ID you&apos;d like to audit.
          </Box>
          <form onSubmit={handleGetAudit}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box>
                <TextField
                  value={search.vaId}
                  label="VA ID"
                  onChange={(e) => setSearch({ ...search, vaId: e.target.value })}
                  variant="outlined"
                  color="secondary"
                  sx={{ marginRight: '16px', minWidth: '270px' }}
                />
                <TextField
                  value={search.productId}
                  label="Product ID"
                  onChange={(e) => setSearch({ ...search, productId: e.target.value })}
                  variant="outlined"
                  color="secondary"
                  sx={{ marginRight: '16px', minWidth: '270px' }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
              >
                Audit
              </Button>
            </Box>
          </form>
          {!loading && data?.auditLogs && (
            <>
              {data.auditLogs?.entries?.length > 0 ? (
                <TableContainer component={Paper} sx={{ marginTop: '24px' }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Product&nbsp;Id</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Old&nbsp;Queue</TableCell>
                        <TableCell>New&nbsp;Queue</TableCell>
                        <TableCell>Old&nbsp;State</TableCell>
                        <TableCell>New&nbsp;State</TableCell>
                        <TableCell>Event&nbsp;Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Product Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.auditLogs?.entries?.map((row) => (
                        <TableRow
                          key={row.audit_id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{row.actor}</TableCell>
                          <TableCell>{row.product_id}</TableCell>
                          <TableCell>{row.chosen_category?.title}</TableCell>
                          <TableCell>{row.queue_old}</TableCell>
                          <TableCell>{row.queue_new}</TableCell>
                          <TableCell>{row.acceptance_state_old}</TableCell>
                          <TableCell>{row.acceptance_state_new}</TableCell>
                          <TableCell>{row.event_type}</TableCell>
                          <TableCell>{new Date(Number(row.action_ts) * 1000).toLocaleDateString("en-US")}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleOpenModal(row.product_id)}
                            >
                              Move to Queue
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box mt={2} color="error.main" textAlign="center">
                  No results found. Please confirm this email is correct.
                </Box>
              )}
            </>
          )}
        </Box>
      </IntroPageContent>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Success"
      />
      <Dialog
        open={openModal}
        onClose={handleClose}
      >
        <DialogTitle>
          <h2>
            Send to Queue
          </h2>
        </DialogTitle>
        <DialogContent>
          Choose a queue to send&nbsp;
          {selectedProduct}
          &nbsp; to:
          <FormControl>
            <RadioGroup
              value={selectedQueue}
              onChange={(e) => setSelectedQueue(e.target.value)}
            >
              <FormControlLabel value="CONFIRM" control={<Radio />} label="Confirm" />
              <FormControlLabel value="ASSIGN" control={<Radio />} label="Assign" />
              <FormControlLabel value="TRIAGE" control={<Radio />} label="Triage" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
          <Button onClick={sendToQueue} disabled={busy} color="primary" variant="contained">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Audit.propTypes = {
  enableView: PropTypes.bool.isRequired,
};

export default Audit;
