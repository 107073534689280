import { onError } from '@apollo/client/link/error';
import PubSub from 'pubsub-js';

export default onError(({
  graphQLErrors, networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations }) => {
      console.error('message', message, 'locations', locations);
      if (message?.toLocaleLowerCase() === 'not authorized') {
        PubSub.publish('network.not_authorized');
      }
    });
  }

  if (networkError?.toString().includes('code 401')) {
    PubSub.publish('network.not_authorized');
  }
});
