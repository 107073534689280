import React  from "react";
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

const MatureCheckbox = ({ onCheck, mature }) => {
  return (
    <Box fontSize={16}>
      <FormControlLabel
        control={<Checkbox color="secondary" onChange={onCheck} checked={mature} />}
        label={<Typography fontSize={16}>Mature Content</Typography>}
        style={{ marginRight: 0 }}
      />
    </Box>
  );
};

MatureCheckbox.propTypes = {
  onCheck: PropTypes.func.isRequired,
  mature: PropTypes.bool,
};

MatureCheckbox.defaultProps = {
  mature: false,
}

export default MatureCheckbox;
