import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Login from './views/Login'
import App from './App';
import { datadogRum } from '@datadog/browser-rum';

const Root = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, user, } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Login loginWithRedirect={loginWithRedirect} />
    )
  }

  if (isAuthenticated && process.env.REACT_APP_ENV === 'production') {
    const session = datadogRum.getInternalContext();
    datadogRum.setUser({
      id: session?.session_id,
      name: user.name,
      email: user.email,
    })
  }

  return (
    <App />
  );
};

export default Root;
