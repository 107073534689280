import React from "react";
import { Navigate } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { useParams } from 'react-router-dom';
import { QueueCounts } from '../gql/queries';
import AFKHandler from "../components/AFKHandler";
import Error from "./Error";
import Confirm from "./Confirm";
import Assign from "./Assign";
import Triage from "./Triage";

const QueueContainer = () => {
  const { loading, data, error } = useQuery(QueueCounts);
  const { queueName } = useParams();
  const currentPath = queueName.toUpperCase();

  if (loading) return null;

  try {
    if (error) {
      throw error.graphQLErrors?.[0];
    }

    const showCongrats = !data?.queueCounts?.counts?.find(x => x.queue === currentPath)?.count;

    if (showCongrats) return (
      <Navigate to="/congratulations" />
    );

    return (
      <>
        <AFKHandler data={data} />
        {currentPath === 'CONFIRM' && <Confirm />}
        {currentPath === 'ASSIGN' && <Assign />}
        {currentPath === 'TRIAGE' && <Triage />}
      </>
    );
  } catch (err) {
    console.error(err);
    return <Error err={err} />;
  }
};

export default QueueContainer;
