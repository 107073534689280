import React from "react";
import { useQuery } from "@apollo/client";
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import { RelatedImages } from '../gql/queries';

const ProductImage = styled('div')(({ image }) => ({
  backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '120px',
  width: '85px',
  margin: '0 auto',
}));

const RelatedCategoryImages = ({ suggestedCategory, currentCategory, showCurrentCategory }) => {
  const { data, loading, refetch } = useQuery(RelatedImages, {
    variables: {
      category_id: suggestedCategory?.id,
    },
    skip: !suggestedCategory?.id,
  });

  if (loading) return null;

  const handleRefresh = () => {
    refetch();
  };

  return (
    <Box>
      <Box ml={1} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Box component="h1" mb={0.5}>
            {suggestedCategory?.breadcrumbs}
          </Box>
          {showCurrentCategory && (
            <Box component={suggestedCategory?.breadcrumbs ? 'p' : 'h1'}>
              Current category:&nbsp;
              {currentCategory?.breadcrumbs || 'None'}
            </Box>
          )}
        </Box>
        {data?.relatedImagesForCategory && (
          <Box color="text.secondary" mr={1.5} fontSize={14} sx={{ cursor: 'pointer' }} onClick={handleRefresh}>
            Refresh Images&nbsp;
            <FontAwesomeIcon icon={faSyncAlt} size="sm" />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" overflow="scroll">
        {data?.relatedImagesForCategory?.images?.map((image, i) => (
          <Box key={i} mx={1} mt={3} minHeight={120}>
            <ProductImage image={image?.url} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

RelatedCategoryImages.propTypes = {
  suggestedCategory: PropTypes.instanceOf(Object),
  currentCategory: PropTypes.instanceOf(Object),
  showCurrentCategory: PropTypes.bool,
};

RelatedCategoryImages.defaultProps = {
  suggestedCategory: {},
  currentCategory: {},
  showCurrentCategory: false,
};

export default RelatedCategoryImages;
