import { gql } from "@apollo/client";

const ProductToCategorize = gql`
  query ProductToCategorize ($queue: String) {
    productToCategorize (queue: $queue) {
      product_id
      provider_id
      title
      description
      mature
      featured_image {
        id
        url
      }
      online_store_url
      images {
        id
        url
      }
      tags
      suggested_categories {
        id
        title
        breadcrumbs
      }
      current_categories {
        id
        title
        breadcrumbs
      }
    }
    productCategoriesList {
      categories {
      id
      title
      subCategories {
        id
        title
        subCategories {
          id
          title
          subCategories {
            id
            title
            subCategories {
              id
              title
              subCategories {
                id
                title
                subCategories {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
  }`

export default ProductToCategorize;
