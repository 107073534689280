/* eslint-disable no-undef */
import React from "react";
import PropTypes from 'prop-types';
import { useQuery } from "@apollo/client";
import { Box, Tooltip } from '@mui/material';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles'
import styled from '@emotion/styled';
import { IntroPageContent } from '../commonStyledComponents';
import { useAuth0 } from "@auth0/auth0-react";
import Confirm from '../assets/Confirm.png';
import Assign from '../assets/Assign.png';
import Triage from '../assets/Triage.png';
import IntroHeader from "../components/IntroHeader";
import { QueueCounts } from '../gql/queries';
import Error from "./Error";

const Image = styled.div`
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
  width: 200px;
  margin: 0 auto;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  a {
    text-decoration: none;
    color: inherit;
    h1 {
      margin: 0;
    }
    &:hover {
      .upper-link {
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border: 1px solid ${props => props.borderColor};
        border-bottom: 0;
      }
      .lower-link {
        border: 1px solid ${props => props.borderColor};
        border-top: 0;
      }
    }
  }
`;

const Queue = styled(Box)`
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px;
  margin: 24px 0;
`;

const Home = ({ showAudit }) => {
  const { user } = useAuth0();
  const theme = useTheme();
  const { loading, data, error } = useQuery(QueueCounts);

  if (loading) return null;

  try {
    if (error) {
      throw error.graphQLErrors?.[0];
    }

    const confirmCount = data?.queueCounts?.counts?.find(q => q.queue === 'CONFIRM')?.count;
    const assignCount = data?.queueCounts?.counts?.find(q => q.queue === 'ASSIGN')?.count;
    const triageCount = data?.queueCounts?.counts?.find(q => q.queue === 'TRIAGE')?.count;

    return (
      <>
        <IntroHeader />
        <IntroPageContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box textAlign="center">
            <Box component="h1" mb={5}>
              Welcome,&nbsp;
              {user.name}
              .
              <br />
              What would you like to do today?
            </Box>
            <LinksContainer borderColor={theme.palette.taupe[300]}>
              <Tooltip title={confirmCount ? '' : 'Nothing to do.'}>
                <Link  to={confirmCount ? 'queue/confirm' : ''}>
                  <Box sx={{ maxWidth: '250px' }}>
                    <Box className="upper-link" mx={1}>
                      <Image url={Confirm} />
                      <Box component="h1">
                        Confirm
                      </Box>
                    </Box>
                    <Box className="lower-link" sx={{ fontSize: '11px', mx: 1, px: 0.5 }}>
                      <Box color="text.secondary" fontStyle="italic" pt={2}>
                        Determine whether a product is correctly categorized or not.
                      </Box>
                      <Queue backgroundColor={theme.palette.primary[200]}>
                        {confirmCount || 0}
                        &nbsp;IN QUEUE
                      </Queue>
                    </Box>
                  </Box>
                </Link>
              </Tooltip>
              <Tooltip title={assignCount ? '' : 'Nothing to do.'}>
                <Link to={assignCount ? 'queue/assign' : ''}>
                  <Box sx={{ maxWidth: '250px' }}>
                    <Box className="upper-link" mx={1}>
                      <Image url={Assign} />
                      <Box component="h1">
                        Assign
                      </Box>
                    </Box>
                    <Box className="lower-link" sx={{ fontSize: '11px', mx: 1, px: 0.5 }}>
                      <Box color="text.secondary" fontStyle="italic" pt={2}>
                        Reassign products to a more accurate category, if necessary.
                      </Box>
                      <Queue backgroundColor={theme.palette.warning[200]}>
                        {assignCount || 0}
                        &nbsp;IN QUEUE
                      </Queue>
                    </Box>
                  </Box>
                </Link>
              </Tooltip>
              <Tooltip title={triageCount ? '' : 'Nothing to do.'}>
                <Link to={triageCount ? 'queue/triage' : ''}>
                  <Box sx={{ maxWidth: '250px' }}>
                    <Box className="upper-link" mx={1}>
                      <Image url={Triage} />
                      <Box component="h1">
                        Triage
                      </Box>
                    </Box>
                    <Box className="lower-link" sx={{ fontSize: '11px', mx: 1, px: 0.5 }}>
                      <Box color="text.secondary" fontStyle="italic" pt={2}>
                        Correct categorization on products reported as being incorrectly categorized.
                      </Box>
                      <Queue backgroundColor={theme.palette.blue[200]}>
                        {triageCount || 0}
                        &nbsp;IN QUEUE
                      </Queue>
                    </Box>
                  </Box>
                </Link>
              </Tooltip>
            </LinksContainer>
          </Box>
        </IntroPageContent>
        {
          showAudit && (
            <Box textAlign="center" color="text.secondary" mt={-2.5}>
              <Link to="/audit" style={{ textDecoration: 'underline' }}>
                Audit Page
              </Link>
            </Box>
          )
        }
        <Box my={3} textAlign="center">
          <small>You are running this application in <b>{process.env.REACT_APP_ENV}</b> mode.</small>
        </Box>
      </>
    );
  } catch (err) {
    console.error(err);
    return <Error err={err} />;
  }
};

Home.propTypes = {
  showAudit: PropTypes.bool,
};

Home.defaultProps = {
  showAudit: false,
};

export default Home;
