import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles'
import { Collapse, MenuItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CollapsibleCategoryMenuItem = ({ category, layer, handleSelectDropdownOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const handleClick = (cat) => {
    if (cat.subCategories?.length === 0) {
      return handleSelectDropdownOption(cat);
    }

    setIsOpen(!isOpen);
  }

  return (
    <>
      <MenuItem onClick={() => handleClick(category)} sx={{ pl: layer, display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          color={category.title === 'Does Not Exist' ? 'error' : (isOpen ? theme.palette.purple[500] : 'secondary') }
          sx={{ maxWidth: '180px', whiteSpace: 'break-spaces' }}
        >
          {category.title}
        </Typography>
        {isOpen && (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
      </MenuItem>
      {category.subCategories?.length > 0 && (
        <Collapse
          in={isOpen}
          unmountOnExit
        >
          {category.subCategories.map(subC => {
            return (
              <CollapsibleCategoryMenuItem key={subC.id} category={subC} handleSelectDropdownOption={handleSelectDropdownOption} layer={layer + 2} />
            );
          })}
        </Collapse>
      )}
    </>
  );
}

CollapsibleCategoryMenuItem.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
  layer: PropTypes.number.isRequired,
  handleSelectDropdownOption: PropTypes.func.isRequired,
};

export default CollapsibleCategoryMenuItem;
