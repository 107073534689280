import React from "react";
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import IntroHeader from "../components/IntroHeader";
import { IntroPageContent } from '../commonStyledComponents';

const Login = ({ loginWithRedirect }) => {
  const theme = useTheme();

  return (
    <Box backgroundColor={theme.palette.taupe[200]} height="100%">
      <IntroHeader />
      <IntroPageContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box textAlign="center">
          <Box component="h1">
            Please sign in to continue.
          </Box>
          <Button color="primary" variant="contained" onClick={loginWithRedirect}>
            SIGN IN
          </Button>
        </Box>
      </IntroPageContent>
    </Box>
  );
};

Login.propTypes = {
  loginWithRedirect: PropTypes.func.isRequired,
};

export default Login;
