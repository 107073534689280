import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTheme } from '@mui/material/styles'
import { useQuery } from "@apollo/client";
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { ActionDescriptor } from "../commonStyledComponents";
import CollapsibleCategoryMenuItem from "./CollapsibleCategoryMenu";
import ConfirmationSnackMessage from "./ConfirmationSnackMessage";
import boldLastBreadCrumb from '../utils/boldLastBreadcrumb';
import SearchCategoryMenuItems from './SearchCategoryMenuItems';
import ProductCategoriesList from "../gql/queries/ProductCategoriesList";
import MatureCheckbox from "./MatureCheckbox";

const AssignActions = ({ product, style }) => {
  const theme = useTheme();
  const dropDownEl = useRef(null);
  const [mature, setMature] = useState(null);
  const [search, setSearch] = useState('');
  const [catSelected, setCatSelected] = useState({
    id: '',
    title: '',
    breadCrumbs: '',
  });
  const { data, loading } = useQuery(ProductCategoriesList, {
    variables: {
      search_term: search.length > 2 ? search : '',
    },
    fetchPolicy: 'network-only',
  });

  const [error, setError] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const showConfirmationRef = useRef(catSelected);
  const { suggested_categories } = product;

  const handleKeyPress = (event) => {
    if (showConfirmationRef.current.id) return null;
    switch (event.key) {
      case (suggested_categories[Number(event.key - 1)]?.id && event.key):
        handleSelect(suggested_categories[Number(event.key - 1)]);
        break;
      case (suggested_categories.length + 1).toString():
        setAnchorEl(lastEl => lastEl ? null : dropDownEl.current);
        break;
      default:
    }
  };

  useEffect(() => {
    if (typeof mature !== 'boolean') {
      setMature(product.mature);
    }

    if (suggested_categories.length) {
      document.addEventListener("keydown", handleKeyPress, false);

      return () => {
        document.removeEventListener("keydown", handleKeyPress, false);
      };
    }
    return undefined;
  }, [product]);

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearch(e.target.value);
  }

  const debounceSearch = useMemo(() => {
    return debounce(handleSearch, 500);
  });

  if (!product) return null;

  const closeSnackMessage = () => {
    setMature(null);
    handleSelect({ id: '', title: '', breadcrumbs: '' });
    showConfirmationRef.current = { id: '', title: '', breadcrumbs: '' };
  }

  const purples = Object.keys(theme.palette.purple).slice(2);

  const categories = data?.productCategoriesList?.categories || [];

  const updatedCats = [
    ...categories,
    {
      id: -1,
      title: 'Does Not Exist',
      subCategories: [],
    }
  ];

  const handleSelect = (category) => {
    setCatSelected({ id: category.id, title: category.title, breadcrumbs: category.breadcrumbs });
    setAnchorEl(null);
    showConfirmationRef.current = category;
  };

  const menuVerticalPosition = suggested_categories.length ? 'bottom' : 'top';

  return (
    <Box style={{ ...style }}>
      <Box display='flex' flexDirection="column" justifyContent="space-between" height="100%">
        <Box>
          {!!suggested_categories?.length && (
            <>
              <ActionDescriptor>
                Confirm Category:
              </ActionDescriptor>
              <Box mb={5} display="flex" alignItems="center" justifyContent="flex-end">
                <Box mr={1}>
                  {boldLastBreadCrumb(suggested_categories[0].breadcrumbs, 'flex-end')}
                </Box>
                <Button
                  onClick={() => handleSelect(suggested_categories[0])}
                  color="success"
                  variant="contained"
                  sx={{ minWidth: '40px' }}
                  disabled={!!catSelected.id}
                >
                  1
                </Button>
              </Box>
              <ActionDescriptor>
                Alternate Categories:
              </ActionDescriptor>
              {suggested_categories.slice(1).map((cat, i) => {
                return (
                  <Box mt={3} key={i} display="flex" alignItems="center" justifyContent="flex-end">
                    <Box mr={1}>
                      {boldLastBreadCrumb(cat.breadcrumbs, 'flex-end')}
                    </Box>
                    <Button
                      onClick={() => handleSelect(suggested_categories[i + 1])}
                      variant="contained"
                      sx={{ minWidth: '40px', backgroundColor: theme.palette.purple[purples[i]] }}
                      disabled={!!catSelected.id}
                    >
                      {i + 2}
                    </Button>
                  </Box>
                )
              })}
            </>
          )}
          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
            <Box mr={1}>
              {suggested_categories.length > 0 ? 'Select Another Category:' : 'Select Category'}
            </Box>
            <Button
              color="secondary"
              variant="outlined"
              onClick={(e) => setAnchorEl(e.target)}
              ref={dropDownEl}
              sx={{ minWidth: '40px', minHeight: '36.5px' }}
              disabled={!!catSelected.id}
            >
              <FontAwesomeIcon icon={faCaretDown} size="sm" />
            </Button>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => {
              setSearch('');
              setAnchorEl(null);
            }}
            PaperProps={{
              style: { width: '350px', height: '500px', overflow: 'scroll' }
            }}
            anchorOrigin={{
              vertical: menuVerticalPosition,
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: menuVerticalPosition,
              horizontal: 'right',
            }}
          >
            <MenuItem>
              <TextField
                label="Search..."
                onKeyDown={e => e.stopPropagation()}
                onChange={debounceSearch}
                variant="outlined"
                color="secondary"
                fullWidth
              />
            </MenuItem>
            {!loading && search.length > 2 && (
              <SearchCategoryMenuItems
                search={search}
                setSearch={setSearch}
                handleSelectDropdownOption={handleSelect}
                categories={categories}
              />
            )}
            {!loading && search.length <= 2 && updatedCats.map((c) => {
              return (
                <CollapsibleCategoryMenuItem
                  key={c.id}
                  category={c}
                  handleSelectDropdownOption={handleSelect}
                  layer={2}
                />
              );
            }
            )}
          </Menu>
        </Box>
        <Box>
          <Box mt={6} textAlign="right">
            <MatureCheckbox mature={!!mature} onCheck={() => setMature(!mature)} />
          </Box>
          {error && (
            <Box color="error.main" mt={2}>
              {error}
            </Box>
          )}
        </Box>
      </Box>
      {
        !!catSelected.id && (
          <ConfirmationSnackMessage
            category={catSelected}
            mature={mature}
            closeSnackMessage={closeSnackMessage}
            setError={setError}
            product={product}
          />
        )
      }
    </Box >
  );
};

AssignActions.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  categories: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
};

AssignActions.defaultProps = {
  style: {},
};

export default AssignActions;
