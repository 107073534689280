import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AFKHandler = ({ data }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => navigate('/'), 600000);
    return () => clearTimeout(timer);
  }, [data]);

  return <div />
};

AFKHandler.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AFKHandler;
