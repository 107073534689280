import React from "react";
import { useTheme } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import Logo from '../assets/Logo.png';

const CarroLogo = styled('div')({
  backgroundImage: `url(${Logo})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  height: '22px',
  width: '100px',
  margin: '0 auto',
});

const IntroHeader = () => {
  const theme = useTheme();
  const { isAuthenticated, logout } = useAuth0();

  return (
    <Box height={160} display="flex" alignItems="center" justifyContent="center" backgroundColor={theme.palette.taupe[200]} position="relative">
      <Link to="/">
        <Box textAlign="center">
          <CarroLogo />
          <Box textTransform="uppercase" mt={2} fontSize={14} fontWeight={600} letterSpacing={1.5}>
            Product Organization Tool
          </Box>
        </Box>
      </Link>
      {isAuthenticated && (
        <Box position="absolute" top="8px" right="8px">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </Button>
        </Box>
      )}
    </Box >
  );
};

export default IntroHeader;
