import { gql } from "@apollo/client";

const ProductCategoriesList = gql`
  query productCategoriesList($search_term: String) {
    productCategoriesList(search_term: $search_term) {
      categories {
      id
      title
      subCategories {
        id
        title
        subCategories {
          id
          title
          subCategories {
            id
            title
            subCategories {
              id
              title
              subCategories {
                id
                title
                subCategories {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export default ProductCategoriesList;
