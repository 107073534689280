/* eslint-disable no-undef */
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client/react';
import { useAuth0 } from "@auth0/auth0-react";
import { setContext } from '@apollo/client/link/context';
import errorLink from './errorLink';
import { RetryLink } from '@apollo/client/link/retry';
import store from '../utils/store';

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently();
    store.setItem('token', token);

    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authLink,
      new RetryLink(),
      httpLink,
    ]),
    cache: new InMemoryCache(),
    dataIdFromObject: (obj) => {
      if (!obj.id) {
        console.warn(obj, 'is missing id field');
        return null;
      }
      return obj.id;
    },
  });

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  )
};

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthorizedApolloProvider;
