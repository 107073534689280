import { gql } from "@apollo/client";

const SetProductQueue = gql`
  mutation SetProductQueue($set_product_queue: ProductQueueSetRequestType) {
    setProductQueue(set_product_queue: $set_product_queue) {
      success
      message
    }
  }`

export default SetProductQueue;
