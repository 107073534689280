import { datadogRum } from '@datadog/browser-rum';

const initExternalApis = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    try {
      datadogRum.init({
        applicationId: '5d63a30c-faf3-4c0e-b3e1-7b75ce6390de',
        clientToken: 'pub2b8fa3615315a041aa7ebc656bbe6e4a',
        site: 'datadoghq.com',
        service: 'taxonomy-tool',
        version: process.env.REACT_APP_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        env: process.env.REACT_APP_ENV,
      });
      datadogRum.startSessionReplayRecording();
      console.log('Datadog session recording is active');
    } catch (err) {
      console.error(err);
    }
  }
}

export default initExternalApis;
