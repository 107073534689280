import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import Root from "./Root";
import { ThemeProvider } from '@mui/material/styles';
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import themes from './themes';
import AuthorizedApolloProvider from "./apollo/AuthorizedApolloProvider";
import initExternalApis from "./utils/initExternalAPIs";

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
};

initExternalApis();

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <AuthorizedApolloProvider>
      <ThemeProvider theme={themes()}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </ThemeProvider>
    </AuthorizedApolloProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
