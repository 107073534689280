import { gql } from "@apollo/client";

const AuditLogs = gql`
  query AuditLogs($actor_id: String $product_id: String) {
    auditLogs(actor_id: $actor_id product_id: $product_id) {
      entries {
        audit_id
        product_id
        actor
        action_ts
        acceptance_state_old
        acceptance_state_new
        chosen_category {
          id
          title
        }
        queue_old
        queue_new
        event_type
      }
    }
  }`

export default AuditLogs;
