import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';

const PageHeader = ({ title, backgroudColor }) => {
  return (
    <Box backgroundColor={backgroudColor} display="flex" justifyContent="space-between" fontSize={12} p={2}>
      <Box ml={1.5} textTransform="uppercase" letterSpacing={1.1}>
        {title}
        :
      </Box>
      <Link to="/">
        <Box display="flex" alignItems="end" mr={2}>
          Exit to Main Menu&nbsp;&nbsp;
          <FontAwesomeIcon icon={faSignOut} />
        </Box>
      </Link>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backgroudColor: PropTypes.string.isRequired,
};

export default PageHeader;
