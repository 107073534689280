import { styled } from '@mui/system';

export const IntroPageContent = styled('section')({
  padding: '18px 20px 12px',
  height: 'calc(100% - 250px)',
});

export const PageContent = styled('section')({
  padding: '18px 20px 12px',
  height: 'calc(100% - 76px)',
  maxWidth: 1400,
  margin: '0 auto'
});

export const ProductDescriptor = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  letterSpacing: 1.5,
  textTransform: 'uppercase',
  fontSize: '10px',
  fontWeight: 600,
  marginBottom: 20,
}));

export const ActionDescriptor = styled(ProductDescriptor)`
  text-align: right;
`;