import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { ActionDescriptor } from "../commonStyledComponents";
import ConfirmationSnackMessage from "./ConfirmationSnackMessage";
import boldLastBreadCrumb from '../utils/boldLastBreadcrumb';
import MatureCheckbox from "./MatureCheckbox";

const ConfirmActions = ({ product, style }) => {
  const [error, setError] = useState('');
  const [catSelected, setCatSelected] = useState({
    id: '',
    title: '',
  });
  const [mature, setMature] = useState(null);
  const showConfirmationRef = useRef(catSelected);

  const handleKeyPress = (event) => {
    if (showConfirmationRef.current.id) return null;
    switch (event.key) {
      case '1':
        handleSelect(product?.suggested_categories?.[0]);
        break;
      case '2':
        handleSelect({ title: 'Other', id: -1 });
        break;
      default:
    }
  };

  useEffect(() => {
    if (typeof mature !== 'boolean') {
      setMature(product.mature);
    }
  
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [product]);

  if (!product) return null;

  const { suggested_categories } = product;

  const closeSnackMessage = () => {
    setMature(null);
    handleSelect({ id: '', title: '' });
    showConfirmationRef.current = { id: '', title: '' };
  }

  const handleSelect = (category) => {
    setCatSelected(category);
    showConfirmationRef.current = category;
  }

  return (
    <Box style={{ ...style }}>
      <Box display='flex' flexDirection="column" justifyContent="space-between" height="100%">
        <Box>
          <ActionDescriptor>
            Confirm Category:
          </ActionDescriptor>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Box mr={1}>
              {boldLastBreadCrumb(suggested_categories?.[0]?.breadcrumbs, 'flex-end')}
            </Box>
            <Button
              onClick={() => handleSelect(product?.suggested_categories?.[0])}
              color="success"
              variant="contained"
              sx={{ justifyContent: "flex-start", minWidth: '40px' }}
              disabled={!!catSelected.id}
            >
              1
            </Button>
          </Box>
          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
            <Box mr={1}>
              Other
            </Box>
            <Button
              onClick={() => handleSelect({ title: 'Other', id: -1 })}
              color="error"
              variant="contained"
              sx={{ justifyContent: "flex-start", minWidth: '40px' }}
              disabled={!!catSelected.id}
            >
              2
            </Button>
          </Box>
        </Box>
        <Box>
          <Box mt={6} textAlign="right">
            <MatureCheckbox mature={!!mature} onCheck={() => setMature(!mature)} />
          </Box>
          {error && (
            <Box color="error.main" mt={2}>
              {error}
            </Box>
          )}
        </Box>
      </Box>
      {!!catSelected.id && (
        <ConfirmationSnackMessage
          category={catSelected}
          mature={mature}
          closeSnackMessage={closeSnackMessage}
          setError={setError}
          product={product}
        />
      )}
    </Box>
  );
};

ConfirmActions.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
};

ConfirmActions.defaultProps = {
  style: {},
};

export default ConfirmActions;
