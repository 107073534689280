import React from "react";
import { useTheme } from '@mui/material/styles'
import { useQuery } from "@apollo/client";
import { Box, Divider } from '@mui/material';
import PageHeader from '../components/PageHeader';
import { PageContent } from '../commonStyledComponents';
import RelatedCategoryImages from "../components/RelatedCategoryImages";
import ConfirmActions from "../components/ConfirmActions";
import ProductImageShuffle from "../components/ProductImageShuffle";
import ProductDescription from "../components/ProductDescription";
import { ProductToCategorize } from '../gql/queries';
import AFKHandler from "../components/AFKHandler";
import Error from "./Error";

const Confirm = () => {
  const theme = useTheme();
  const { data, loading, error } = useQuery(ProductToCategorize, {
    variables: {
      queue: 'CONFIRM',
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;

  try {
    if (error) {
      throw error.graphQLErrors?.[0];
    }

    return (
      <>
        <AFKHandler data={data} />
        <PageHeader backgroudColor={theme.palette.primary[200]} title="Confirm category" />
        <PageContent>
          <RelatedCategoryImages suggestedCategory={data?.productToCategorize?.suggested_categories?.[0]} />
          <Box my={5} width={`calc(100% + 40px)`} ml={-2.5}>
            <Divider />
          </Box>
          <Box display="flex">
            <ProductImageShuffle images={data?.productToCategorize?.images} style={{ flex: 1 }} />
            <ProductDescription product={data?.productToCategorize} style={{ flex: 0.7, padding: '0px 48px 12px 56px' }} />
            <ConfirmActions product={data?.productToCategorize} style={{ flex: 0.8 }} />
          </Box>
        </PageContent>
      </>
    );
  } catch (err) {
    return <Error err={err} />;
  }
};

export default Confirm;
