import React, { useState, useEffect } from "react";
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

const ProductImage = styled('div', {
  shouldForwardProp: (prop) => prop !== "image"
})(({ image }) => ({
  backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '425px',
  width: '300px',
  margin: '0 auto',
}));

const ImagesContainer = styled('div')({
  display: 'flex',
  height: '550px',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
})

const MultiImage = styled(ProductImage)(({ imagestyle }) => ({
  position: 'absolute',
  transition: 'all 0.5s ease-in-out',
  transform: 'translateX(-50%) translateY(-50%)',
  top: '50%',
  ...imagestyle,
}));

const SquareButton = styled(Button)({
  height: '40px',
  width: '40px',
  minWidth: '40px',
});


const ProductImageShuffle = ({ images, style }) => {
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  const handleKeyPress = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        setImageIndex(lastIndex => {
          if (lastIndex > 0) return lastIndex - 1;
          return lastIndex;
        });
        break;
      case 'ArrowRight':
        setImageIndex(lastIndex => {
          if (lastIndex < images.length -1) return lastIndex + 1;
          return lastIndex;
        });
        break;
      default:
    }
  };

  if (!images || images.length === 0) return null;

  if (images.length === 1) {
    return (
      <Box sx={{ ...style }}>
        <ProductImage image={images[0].url} />
      </Box>
    );
  }

  const getImageStyleObject = (index) => {
    if (imageIndex === index) {
      return {
        opacity: 1,
        left: '50%',
        zIndex: 1,
        transform: 'translateX(-50%) translateY(-50%) scale(1.2)',
      }
    }
    else if (imageIndex - index === -1) {
      return {
        opacity: 0.3,
        left: '65%'
      }
    } else if (imageIndex - index === 1) {
      return {
        opacity: 0.3,
        left: '35%'
      }
    }
    else {
      return {
        opacity: 0,
        display: 'none',
      }
    }
  };

  return (
    <Box sx={{ ...style, position: 'relative' }}>
      <ImagesContainer>
        {images?.map((image, i) => {
          return (
            <MultiImage
              key={i}
              className={imageIndex === i ? 'slide-active' : 'slide'}
              image={image.url}
              imagestyle={getImageStyleObject(i)}
            />
          );
        })}
      </ImagesContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '-20px' }}>
        <SquareButton
          shape="square"
          disabled={imageIndex === 0}
          color="secondary"
          variant="contained"
          onClick={() => setImageIndex(imageIndex - 1)}
        >
          <FontAwesomeIcon icon={faCaretLeft} size="lg" />
        </SquareButton>
        <SquareButton
          shape="square"
          disabled={imageIndex === images.length - 1}
          color="secondary"
          variant="contained"
          onClick={() => setImageIndex(imageIndex + 1)}
        >
          <FontAwesomeIcon icon={faCaretRight} size="lg" />
        </SquareButton>
      </Box>
    </Box>
  );
};

ProductImageShuffle.propTypes = {
  images: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
};

ProductImageShuffle.defaultProps = {
  style: {},
};

export default ProductImageShuffle;
