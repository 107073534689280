import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import IntroHeader from "../components/IntroHeader";
import { IntroPageContent } from '../commonStyledComponents';

const Error = ({ err }) => {
  const navigate = useNavigate();

  const errorMessage = err?.message || err;

  return (
    <Box height="100%">
      <IntroHeader />
      <IntroPageContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box textAlign="center">
          <Box component="h1">
            Oops!
          </Box>
          <Box>
            Something went wrong here. Please contact one of our developers.
          </Box>
          {errorMessage && (
            <Box mt={3}>
              Error -&nbsp;
              {errorMessage}
            </Box>
          )}
          {window.location.pathname !== '/' && (
            <Box mt={3}>
              <Button color="primary" variant="contained" onClick={() => navigate('/')}>
                Exit to Main Menu
              </Button>
            </Box>
          )}
        </Box>
      </IntroPageContent>
    </Box>
  );
};

Error.propTypes = {
  err: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
};

Error.defaultProps = {
  err: '',
};

export default Error;
