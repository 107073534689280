import { gql } from "@apollo/client";

const RelatedImages = gql`
  query RelatedImages($category_id: Int) {
    relatedImagesForCategory(category_id: $category_id, limit: 12) {
      images {
        id
        url
        alt_text
      }
    }
  }`

export default RelatedImages;
