import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import IntroHeader from "../components/IntroHeader";
import { IntroPageContent } from '../commonStyledComponents';
import highFive from '../assets/HighFive.png';

const HighFive = styled.div`
  background-image: url(${highFive});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 275px;
  width: 570px;
  margin: 0 auto;
`;

const Congratulations = () => {
  const navigate = useNavigate();

  return (
    <Box height="100%">
      <IntroHeader />
      <IntroPageContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box textAlign="center">
          <Box component="h1">
            Congratulations!
          </Box>
          <HighFive />
          <Box component="h5" mb={6}>
            You&apos;ve completed all the work in this queue.
          </Box>
          <Button color="primary" variant="contained" onClick={() => navigate('/')}>
            Exit to Main Menu
          </Button>
        </Box>
      </IntroPageContent>
    </Box>
  );
};

export default Congratulations;
