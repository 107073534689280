import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import { useAuth0 } from "@auth0/auth0-react";
import QueueContainer from "./views/QueueContainer";
import Congratulations from "./views/Congratulations";
import Audit from "./views/Audit";
import ForceError from './views/ForceError';

const App = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [permissions, setPermissions] = useState([]);

  useEffect(async () => {
    const token = await getAccessTokenSilently();
    const decodedToken = jwt_decode(token);
    setPermissions(decodedToken?.permissions);
  }, [user?.sub]);

  const showAudit = /@vyrl.co/.test(user.email) || ['read:audit', 'write:audit'].every(scope => permissions.includes(scope));

  return (
    <Routes>
      <Route path="/" exact element={<Home showAudit={showAudit} />} />
      <Route path="/audit" exact element={<Audit enableView={showAudit} />} />
      <Route path="/queue/:queueName" exact element={<QueueContainer />} />
      <Route path="/congratulations" exact element={<Congratulations />} />
      <Route path="/force-error" exact element={<ForceError />} />
    </Routes>
  );
};

export default App;
