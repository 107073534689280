import React from "react";
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import boldLastBreadCrumb from '../utils/boldLastBreadcrumb';

const SearchCategoryMenuItems = ({ setSearch, categories, handleSelectDropdownOption }) => {
  const handleSelect = (cat) => {
    setSearch('');
    handleSelectDropdownOption({
      id: cat.id,
      title: cat.title.split(' -> ').pop(),
    })
  }

  return (
    <>
      {categories?.length ? categories.map((cat, i) => (
        <MenuItem key={i} onClick={() => handleSelect(cat)} style={{ whiteSpace: 'normal' }}>
          {boldLastBreadCrumb(cat.title)}
        </MenuItem>
      )) : (
        <Box p={2} textAlign="center">
          No Search Results Found...
        </Box>
      )}
    </>
  );
};

SearchCategoryMenuItems.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
  handleSelectDropdownOption: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default SearchCategoryMenuItems;



