import { gql } from "@apollo/client";

const UpdateProduct = gql`
  mutation UpdateProduct($product_update_request: ProductUpdateRequestType) {
    updateProduct(product_update_request: $product_update_request) {
      success
      message
    }
  }`

export default UpdateProduct;
