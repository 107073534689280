export default function themePalette(theme) {
  return {
    mode: theme?.customization?.navType,
    common: {
      black: theme.colors?.darkPaper
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark,
      200: theme.colors?.primary200,
      800: theme.colors?.primary800
    },
    secondary: {
      light: theme.colors?.secondaryLight,
      main: theme.colors?.secondaryMain,
      dark: theme.colors?.secondaryDark,
      200: theme.colors?.secondary200,
      800: theme.colors?.secondary800
    },
    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark
    },
    blue: {
      light: theme.colors?.blueLight,
      200: theme.colors?.blue200,
      main: theme.colors?.blueMain,
      dark: theme.colors?.blueDark
    },
    warning: {
      light: theme.colors?.warningLight,
      200: theme.colors?.warning200,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      300: theme.colors?.success300,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark
    },
    taupe: {
      50: theme.colors?.taupe50,
      100: theme.colors?.taupe100,
      200: theme.colors?.taupe200,
      300: theme.colors?.taupe300,
      500: theme.colors?.taupe500,
      600: theme.colors?.taupe600,
      700: theme.colors?.taupe700,
      900: theme.colors?.taupe900
    },
    purple: {
      100: theme.colors?.purple100,
      200: theme.colors?.purple200,
      300: theme.colors?.purple300,
      400: theme.colors?.purple400,
      500: theme.colors?.purple500,
      600: theme.colors?.purple600,
      700: theme.colors?.purple700,
      900: theme.colors?.purple900
    },
    dark: {
      light: theme.colors?.darkTextPrimary,
      main: theme.colors?.darkLevel1,
      dark: theme.colors?.darkLevel2,
      800: theme.colors?.darkBackground,
      900: theme.colors?.darkPaper
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      dark: theme.textDark,
      hint: theme.colors?.grey100
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault
    }
  };
}
