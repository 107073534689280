const store = {};

store.setItem = (k, v) => {
  sessionStorage.setItem(k, typeof v === 'object' ? JSON.stringify(v) : v);
};

store.getItem = (k) => {
  const v = sessionStorage.getItem(k);
  try {
    return JSON.parse(v);
  } catch (ex) {
    return v;
  }
};

store.removeItem = (k) => sessionStorage.removeItem(k);

store.setItemLocal = (k, v) => {
  try {
    localStorage.setItem(k, typeof v === 'object' ? JSON.stringify(v) : v);
  } catch (err) {
    console.error(err);
  }
};

store.getItemLocal = (k) => {
  try {
    const v = localStorage.getItem(k);
    try {
      return JSON.parse(v);
    } catch (ex) {
      return v;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

store.removeItemLocal = (k) => {
  try {
    localStorage.removeItem(k);
  } catch (err) {
    console.error(err);
  }
};

export default store;
