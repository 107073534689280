import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Button, Snackbar, SnackbarContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ProductToCategorize, QueueCounts } from "../gql/queries";
import { UpdateProduct } from "../gql/mutations";

const ConfirmationSnackMessage = ({
  closeSnackMessage, category, setError, product, mature,
}) => {
  const theme = useTheme();
  const [busy, setBusy] = useState(false);
  const [update] = useMutation(UpdateProduct);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      return handleNext();
    }, 3000);
    return () => {
      window.clearTimeout(timer);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleNext();
      closeSnackMessage();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, []);

  const { product_id } = product;

  const handleClose = (event, reason) => {
    if (reason === 'escapeKeyDown') {
      closeSnackMessage();
    }
  }

  const handleNext = async () => {
    try {
      setBusy(true);
      setError('');
      console.log('calling mutation with ', product_id, category?.id, 'mature - ', mature);
      await update({
        variables: {
          product_update_request: {
            product_id,
            chosen_category_id: category?.id,
            mature,
          },
        },
        refetchQueries: [ProductToCategorize, QueueCounts],
      });
    } catch (err) {
      setError(err.graphQLErrors ? err.graphQLErrors[0].message : err);
    } finally {
      closeSnackMessage();
    }
  }

  const action = (
    <>
      <Box mr={2}>
        <Button color="secondary" variant="contained" disabled={busy} onClick={() => closeSnackMessage()}>
          Undo [Esc]
        </Button>
      </Box>
      <Button color="secondary" variant="contained" disabled={busy} onClick={handleNext}>
        Confirm [Enter]
      </Button>
    </>
  )

  return (
    <Box>
      <Snackbar
        open={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <SnackbarContent
          message={(
            <>
              <FontAwesomeIcon icon={faCheck} size="lg" />
              &nbsp;&nbsp;
              {category?.title}
            </>
          )}
          action={action}
          sx={{ backgroundColor: theme.palette.taupe[700] }}
        />
      </Snackbar>
    </Box>
  );
};

ConfirmationSnackMessage.propTypes = {
  closeSnackMessage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  product: PropTypes.instanceOf(Object).isRequired,
  mature: PropTypes.bool,
};

export default ConfirmationSnackMessage;
